:root {
  --primary: #01506e;
  --mdc-theme-primary: #01506e;
  --primary-color: #01506e;
  --primary-btn: #01506e;
  --primary-blue:#6cade6;
  --primary-95: #00529B;
  --primary-90: #018bbb;
  --primary-80: #0db9f0;

  --white-color: #fff;
  --white-900: #ebedf2;
  --white-800:#f7f9fb;
  --white-700:#faf9fc;
  --white-600: #e5e9ee;
  --white-500: #f0f0f0;
  --white-400: #ced7ec;
  --white-300: #e4e4e4;
  --white-200: #D8D8D8;
  --white-100: #FAFBFF;


  --orange-color:#f16c13;


  --dark-green:#006400;
  --offer-ribbon:#43a838;
  --green-80 :#28a745;


  --black-color: #000000;
  --black-95: #212529;
  --black-90: #212121;


  --footer-dark: #171e24;
  --footer-light: #252f38;
  --gray:#3f4047;
  --gray-dark: #2F353B;
  --gray-color: #575962;
  --gray-label: #7b7e8a;
  --light-gray:#9699a2;
  --gray-800:#5c6d7e;
  --gray-100: #888;
  --gray-60: #999;
  --gray-50: #bbb;

  --blue:#5867dd;
  --blue-90: #5075E4;
  --blue-80: #3598DC;
  --blue-70: #32C5D2;
  --blue-05: #7f99cc;

  --font-danger:#f4516c;
  --pink-80: #e83e8c;


  --offer-card-header: #474747;

}

$black: #000;
$dark-gray: #171e24;
