/* You can add global styles to this file, and also import other style files */
a {
	text-decoration: none;
}

:root {
	--primary: #249bd6;
	--mdc-theme-primary: #249bd6;
	--primary-color: #249bd6;
	--primary-btn: #249bd6;
}
